import React from 'react'
import Nav from './navbar-black'
import Footer from './footer'
import DiscogSlider from './discogslider'
import ContactForm from './contactForm'
import { useState, useEffect } from "react"
import { Outlet, Link, NavLink } from "react-router-dom"

export default function Tunage() {

    useEffect(() => {

        //document.querySelector('a.navbar-links--lrg[href="tunage"]').classList.add('active')
        document.querySelector('a.app[href="/tunage"] .app-wrap').classList.add('active');

        //document.querySelector('.hero').style.height = window.innerHeight - (document.querySelector('.content-wrap').offsetHeight + 48) - 50 + 'px'
        //document.querySelector('#main-tunage').style.height = window.innerHeight + 'px'
        //document.documentElement.style.cssText = "--full-screen-height: " + (window.innerHeight - 50) + "px";
        if (document.querySelector('.fullpage-bgimage-wrap.tunage.img2')) {
            document.querySelector('.fullpage-bgimage-wrap.tunage.img2').style.height = window.innerHeight - 50 + 'px'
        }
        if (document.querySelector('.fullpage-bgimage-wrap.tunage.img3')) {
            document.querySelector('.fullpage-bgimage-wrap.tunage.img3').style.height = window.innerHeight - 50 + 'px'
        }

    })

    return (
        <>
        <Nav />
        <main id="main-tunage" className="tunage">

            {/*
            <div className="fullpage-bgimage-wrap tunage">
                <div className="tunage-header">
                    <h1 className="text-center white">I TOOK A TRIP</h1>
                    <a href="https://open.spotify.com/track/7mytHgxcwE3ANalunS7cp0?si=c99e4671cfe54753" className="cta center">
                        LISTEN NOW
                    </a>
                </div>
            </div>*/}

            {/*
            <div className="teaser teaser1">
                <div className="teaser-image">
                    <img src="/images/disco on neptune artwork final.jpg" alt="newest single artwork"/>
                </div>
                <h2 className="text-center">DISCO ON NEPTUNE</h2>
                <a href="https://open.spotify.com/track/0ZQSuT3HRjOwSewqciJHPo" className="cta center" target="_blank">
                    LISTEN NOW
                </a>
            </div>
            

            <div className="content-wrap">
                <div className="content-container">
                    <div className="fullpage-poster-image">
                        <img src="/images/disco on neptune artwork final.jpg" alt=""/>
                    </div>
                    <div className="content-container-copy">
                        <h2 className="spacing_bottom--sml">Disco on Neptune</h2>
                        <Link reloadDocument to="/shows" className="cta black">PLAY</Link>
                    </div>
                </div>
            </div>
            

            <div className="content-wrap top bgc1">
                <div className="content-container">
                    <div className="fullpage-poster-image">
                        <img src="/images/postershort copy 2.png" alt="upcoming show poster"/>
                    </div>
                    
                    <div className="content-container-copy">
                        <h2 className="spacing_bottom--sml" style={{color: "#febf51"}}>NEXT SHOW</h2>
                        <h3 style={{color: "#febf51"}}>Wednesday 22<sup>nd</sup> January</h3>
                        <h4 className="spacing_bottom--sml" style={{color: "#febf51"}}>@ The Victoria Dalston</h4>
                        <Link to="https://dice.fm/event/nv3pl8-be-lucky-presents-songs-about-love-and-money-22nd-jan-the-victoria-london-tickets" className="cta black victoria25">Get Tickets</Link>
                    </div>
                </div>
            </div>

            */}

            <div className="fullpage-bgimage-wrap tunage img3"></div>
            
            <div className="content-wrap">
                <div className="discog-slider-wrap">
                <h2>RELEASES</h2>
                <div className="record-swiper-wrapper">
                    <DiscogSlider />
                </div>
                </div>
            </div>

            <div className="content-wrap black">
                <div className="contact-wrap form-blk">
                <ContactForm />
                </div>
            </div>

            <div className="fullpage-bgimage-wrap tunage img2"></div>
            
        </main>
        <Footer/>     
        </> 
    )
}