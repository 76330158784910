
import './App.css'
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
//import About from './about'
import Home from './home'
import Tunage from './tunage2'
//import Shows from './shows2'

//import Products from './products'
//import Product000000 from './product_000000'
//import Product000001 from './product_000001'

//import News from './news'
//import Article001 from './article001'

//import Cpod from './cpod'
import Contact from './contact'

function App() {
  return (
      <Routes>
        <Route path='/' element={<Home/>} />
        {/*<Route path='/about' element={<About/>} />*/}
        <Route path="/tunage" element={<Tunage/>} />
        {/*<Route path="/shows" element={<Shows/>} />*/}
        
        {/*<Route path="/news" element={<News/>} />*/}
        {/*<Route path="/news/articles/001" element={<Article001/>} />*/}
        
        <Route path='/contact' element={<Contact/>} />
        {/*<Route path="/cpod" element={<Cpod/>} />*/}

        {/*<Route path="merch" element={<Products/>} />
        <Route path="000000" element={<Product000000/>} />
        <Route path="000001" element={<Product000001/>} />*/}
      </Routes>
  )
}

export default App; 