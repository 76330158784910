import React from 'react'
import { useState, useEffect } from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Autoplay } from "swiper/modules";

export default function DiscogSlider() {

  useEffect(() => {
  
    document.querySelectorAll('.box-slide').forEach(slide => {
      slide.querySelector('.ep-title').innerText = slide.getAttribute('data-ep-title');
    });

  })

  return (
    <>
      <Swiper
        spaceBetween={50}
        loop={false}
        pagination={{ clickable: true }}
        modules={[Autoplay]}
        className="box-swiper"
        
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 5,

          },
          450: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          769: {
            slidesPerView: 3
          },
          950: {
            slidesPerView: 3
          }
        }}
      >
        <SwiperSlide>
          <a className="box-slide" href="https://open.spotify.com/track/6DSZYhFDuoTNN9VU4p0nae" target="_blank" data-ep-title="I Took a Trip">
            <img src="/images/discog/itatartwork.jpg"/>
            <div class="ep-title"></div>
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a className="box-slide" href="https://open.spotify.com/track/6T1c9O55gmaqx53OJNgKaf" target="_blank" data-ep-title="Midnight">
            <img src="/images/discog/midnight artwork final.png"/>
            <div class="ep-title"></div>
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a className="box-slide" href="https://open.spotify.com/track/0ZQSuT3HRjOwSewqciJHPo" target="_blank" data-ep-title="Disco on Neptune">
            <img src="/images/discog/disco on neptune artwork final.jpg"/>
            <div class="ep-title"></div>
          </a>
        </SwiperSlide>
      </Swiper>
    </>
  );
}